import { graphql } from "gatsby"
import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "@root/seo"
import WpAcfColorfulCardsBlock from "@modules/WpAcfColorfulCardsBlock"
import WpAcfStickerModuleBlock from "@modules/WpAcfStickerModuleBlock"
import WpAcfImageModuleBlock from "@modules/WpAcfImageModuleBlock"
import WpAcfHeadlineModuleBlock from "@modules/WpAcfHeadlineModuleBlock"
import WpAcfIntroTextBlock from "@modules/WpAcfIntroTextBlock"
import WpAcfTextImageBlock from "@modules/WpAcfTextImageBlock"
import WpAcfNewsTickerBlock from "@modules/WpAcfNewsTickerBlock"
import WpAcfFormModuleBlock from "@modules/WpAcfFormModuleBlock"
import WpAcfVideoModuleBlock from "@modules/WpAcfVideoModuleBlock"
import WpAcfPartnerLogosTickerBlock from "@modules/WpAcfPartnerLogosTickerBlock"
import WpAcfAwardsModuleBlock from "@modules/WpAcfAwardsModuleBlock"
import WpAcfCasesModuleBlock from "@modules/WpAcfCasesModuleBlock"
import WpAcfCampaignModuleBlock from "@modules/WpAcfCampaignModuleBlock"
import WpAcfBlogAccordionBlock from "@modules/WpAcfBlogAccordionBlock"
import WpAcfQuoteModuleBlock from "@modules/WpAcfQuoteModuleBlock"
import WpAcfKartenModuleBlock from "@modules/WpAcfKartenModuleBlock"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import { useBreakpoint } from "@hooks/useBreakpoint"
import { useStateContext } from "@context/stateContext"
import { Waypoint } from "react-waypoint"
import { motion } from "framer-motion"

const HeroContainer = styled.div`
  ${tw`relative flex flex-col justify-center w-full h-screen px-6 py-10 text-white bg-black font-primary min-h-600px md:px-12`}
`
const HeroImageContainer = styled(motion.div)`
  ${tw`absolute inset-0 w-full h-full `}
`
const PostTitle = styled(motion.h1)`
  ${tw`z-10 font-black text-center text-white uppercase font-primary text-46 md:text-60 lg:text-80`}
`
const ParagraphContainer = styled.div`
  ${tw`py-4 mx-auto my-12 font-bold px-skOffset font-primary xl:text-32 lg:text-24 text-20`}
  max-width: 960px;
`

export default function DefaultPageTemplate({ data }) {
  const page = data.allWpPage.edges[0]?.node
  const seo = page?.seo
  const stateContext = useStateContext()
  const { isScreenLg, isScreenMd, isScreenSm } = useBreakpoint()

  useEffect(() => {
    stateContext.dispatch({ action: "SET_MENUE_DARK" })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleWaypointEnter = () => {
    stateContext.dispatch({ action: "SET_MENUE_LIGHT" })
  }
  const handleWaypointLeave = () => {
    stateContext.dispatch({ action: "SET_MENUE_DARK" })
  }

  if (!seo) return null
  if (!page) return null
  return (
    <>
      <Seo title={`${seo.title || page.title}`} description={`${seo.metaDesc || ""}`} />
      <HeroContainer>
        <HeroImageContainer
          initial={{
            clipPath: "polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%)",
          }}
          animate={{
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          }}
          exit={{
            clipPath: "polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%)",
          }}
          transition={{ duration: 0.3, ease: [0.17, -0.05, 0.26, 0.67] }}
        >
          {page.acfTemplateHeaderImage?.headerImageMobile?.localFile && (
            <GatsbyImage className="absolute object-cover w-full h-full md:!hidden" layout="fullWidth" image={getImage(page.acfTemplateHeaderImage?.headerImageMobile?.localFile.childImageSharp)} alt="" />
          )}
          {page.featuredImage?.node?.localFile && (
            <GatsbyImage className="!hidden md:!block absolute object-cover w-full h-full" layout="fullWidth" image={getImage(page.featuredImage?.node?.localFile.childImageSharp)} alt="" />
          )}
        </HeroImageContainer>
        <PostTitle
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          {parse(page.title)}
        </PostTitle>
      </HeroContainer>
      <Waypoint topOffset="1px" bottomOffset="9999px" onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        exit={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          delay: 0.3,
          duration: 0.5,
          ease: [0.17, -0.05, 0.26, 0.67],
        }}
      >
        {page.blocks.map((keyName, i) => {
          if (keyName.__typename === "WpAcfStickerModuleBlock") {
            return <WpAcfStickerModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfColorfulCardsBlock") {
            return <WpAcfColorfulCardsBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfImageModuleBlock") {
            return <WpAcfImageModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfHeadlineModuleBlock") {
            return <WpAcfHeadlineModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfVideoModuleBlock") {
            return <WpAcfVideoModuleBlock moduleData={keyName.acfBlockVideoModule} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfIntroTextBlock") {
            return <WpAcfIntroTextBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfTextImageBlock") {
            return <WpAcfTextImageBlock moduleData={keyName.textImageBlock} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfNewsTickerBlock") {
            return <WpAcfNewsTickerBlock moduleData={keyName.acfBlockNewsTicker} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfPartnerLogosTickerBlock") {
            return <WpAcfPartnerLogosTickerBlock key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfFormModuleBlock") {
            return <WpAcfFormModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfAwardsModuleBlock") {
            return <WpAcfAwardsModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfCasesModuleBlock") {
            return <WpAcfCasesModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfCampaignModuleBlock") {
            return <WpAcfCampaignModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfBlogAccordionBlock") {
            return <WpAcfBlogAccordionBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfQuoteModuleBlock") {
            return <WpAcfQuoteModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          if (keyName.__typename === "WpAcfKartenModuleBlock") {
            return <WpAcfKartenModuleBlock moduleData={keyName} key={`${keyName.__typename}-${i}`} />
          }
          return (
            <ParagraphContainer className="paragraph-container" key={`${keyName.__typename}-${i}`}>
              {parse(keyName.originalContent)}
            </ParagraphContainer>
          )
        })}
      </motion.div>
    </>
  )
}

export const query = graphql`
  query($page: String) {
    allWpPage(filter: { id: { eq: $page } }) {
      edges {
        node {
          id
          slug
          title
          databaseId
          uri
          template {
            templateName
          }
          seo {
            metaDesc
            title
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
          acfTemplateHeaderImage {
            headerImageMobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
          blocks {
            __typename
            originalContent
            ... on WpAcfIntroTextBlock {
              acfBlockIntroText {
                content
                headline
                subline
              }
            }
            ... on WpAcfCasesSectionBlock {
              attributes {
                anchor
              }
            }
            ... on WpAcfColorfulCardsBlock {
              attributes {
                anchor
                className
              }
              acfBlockColorfulCards {
                lineGap
                cards {
                  slides {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 2000)
                      }
                    }
                  }
                  setAsSlider
                  backgroundColor
                  cardSize
                  description
                  caseDescription
                  descriptionColor
                  caseDescriptionColor
                  headline
                  headlineAlign
                  headlineColor
                  setAsBanner
                  bannerUrl
                  bannerFormat
                  backgroundImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 2000)
                      }
                    }
                  }
                  melting
                  link {
                    target
                    title
                    url
                  }
                  buttonAlignment
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1000)
                      }
                    }
                  }
                }
              }
            }
            ... on WpAcfNewsTickerBlock {
              acfBlockNewsTicker {
                tickerItems {
                  content
                }
                backgroundColor
                textColor
              }
              attributes {
                anchor
                className
              }
            }
            ... on WpAcfPartnerLogosTickerBlock {
              attributes {
                anchor
                className
              }
            }
            ... on WpAcfHeadlineModuleBlock {
              attributes {
                anchor
                className
              }
              acfHeadlineModuleBlock {
                backgroundColor
                headlineColor
                headline
                stickerPosition
                subline
                sublinePosition
                sublineColor
                headlineAsImage
                headlineImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(width: 2000)
                    }
                  }
                }
                button {
                  target
                  title
                  url
                }
                sticker {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            ... on WpAcfImageModuleBlock {
              attributes {
                anchor
              }
              acfImageModuleBlock {
                size
                bannerUrl
                bannerFormat
                backgroundColor
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 2000)
                    }
                  }
                }
              }
            }
            ... on WpAcfStickerModuleBlock {
              attributes {
                anchor
              }
              acfBlockStickerSection {
                headline
                stickersContainer
                stickers {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 800)
                      }
                    }
                  }
                }
              }
            }
            ... on WpAcfTextImageBlock {
              textImageBlock {
                copyColumn
                fieldGroupName
                imagePosition
                imageColumn {
                  id
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 2000)
                    }
                  }
                }
              }
            }
            ... on WpAcfFormModuleBlock {
              attributes {
                anchor
              }
            }
            ... on WpAcfVideoModuleBlock {
              acfBlockVideoModule {
                file
                videoEmbed
                videoFile {
                  link
                  uri
                  title
                }
                thumbnail {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            ... on WpAcfAwardsModuleBlock {
              acfModuleAwards {
                headline
                backgroundColor
                color
                links {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1000)
                      }
                    }
                  }
                  link {
                    title
                    target
                    url
                  }
                }
              }
              attributes {
                anchor
              }
            }
            ... on WpAcfCasesModuleBlock {
              acfModuleCasesModule {
                headline
              }
              attributes {
                anchor
              }
            }
            ... on WpAcfCampaignModuleBlock {
              acfModuleCampaign {
                headline
                cards {
                  title
                  text
                  button {
                    title
                    target
                    url
                  }
                  animationType
                }
              }
              attributes {
                anchor
              }
            }
            ... on WpAcfBlogAccordionBlock {
              modulesBlogAccordion {
                theme
                items {
                  title
                  content
                }
              }
            }
            ... on WpAcfQuoteModuleBlock {
              acfModuleQuoteModule {
                theme
                name
                position
                text
                imagePosition
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 1000)
                    }
                  }
                }
              }
            }
            ... on WpAcfKartenModuleBlock {
              modulesKartenModule {
                theme
                columns
                headline
                items {
                  title
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`
